<template lang="pug">
  div.wrap-module-mypage
    div(v-if="user").user-profile.f.fh
      div.pt10.pb10
        div.f.fc
          v-avatar(size="120px").mb8
            v-img(:src="user.iconURL")
        div.f.fc.mb6
          div.f.fc
            span.block.bold.mr4 {{user.name}}
            //- v-icon.pb-1(@click="$router.push('/settings')" size="18px") mdi-cog
    div.address.text-center(v-if="this.address")
      div.f.fc
        p.bold.mr-2.mb-1 登録中の住所
        v-icon.relative.mt2(style="width:18px;height:18px;cursor:pointer;" @click="editAddress") mdi-pencil
      p.fz12.text-center {{this.address.address}}
    p.fz16.bold.text-center.mb-2 依頼した仕事
    div.wrap-order-lists
      ItemOrderCard(v-for="val in orders" :order="val", :btnText="changeBtnText(val)" @btn-action="actionBy" @delete="deleteOrder" :key="val.id")
    v-dialog(v-model="isShowDialog" overlay-color="#000000" overlay-opacity="0.6")
      v-card.pa-4
        div(v-if="dialogType==='payment'")
          p.fz18.bold.mt-2.text-center(v-if="isShowLoading") 決済リンク生成中
          p.fz18.bold.mt-2.text-center(v-else) 決済ページへ
          div.ma-2
            MinNowLoading.mb-4(:show="isShowLoading")
            div.f.fh.mt-8.mb-8(v-if="!isShowLoading")
              v-btn(color="#E7305B" @click="toPayment")
                span.bold(style="color:#fff;") PayPayでお支払い
        div(v-if="dialogType==='editAddress'")
          p.fz18.bold.mt-2.text-center 住所の変更
          div.ma-2
            div.f.fc
              v-text-field(label="住所" v-model="inputAddress")
            span.block.text-center.fz12.mb28 ※建物や部屋番号までお間違いないようにご入力ください
            div.f.fc
              v-btn(color="#F2F2F2" v-if="address.address === inputAddress || inputAddress === ''")
                span.bold 確定
              v-btn(color="#E7305B" v-else @click="generateGeocode")
                span.bold(style="color:#fff;") 確定

      //- ItemOrderCard(v-for="(val, key) in orders" :order="val", :btnText="changeBtnText(val)" @btn-action="actionBy" @action="showDetail" :key="key")
    //- 編集を実装する？？ ボタンとダイアログの役割は変わるかもしれない
    //- v-dialog(v-model="isShowDetail")
    //-   v-card.pa-4
    //-     div.order-content(v-if="selectedOrder")
    //-       div.bold.mb-2.mt-3
    //-         v-icon mdi-cart
    //-         span.pl-2 お買物代行
    //-         //- v-icon {{ displayCategoryIcon }}
    //-         //- span.pl-2 {{ displayCategoryText }}
    //-       div.bold.mb-2
    //-         v-icon mdi-clock-time-five
    //-         //- span.pl-2 {{ displayTextTime }}
    //-         span.pl-2 {{ displayUrgency }}
    //-       div.bold.mb-2
    //-         v-icon mdi-map
    //-         span.pl-2 {{ selectedOrder.address }}
    //-       div.bold
    //-         v-icon mdi-cash-multiple
    //-         span.pl-2.fz14 ボーナス:
    //-         span {{ selectedOrder.bonus }}
    //-         span.fz14 円
    //-       //- p.mt-2.pl-1.bold.mb-1(v-if="selectedOrder.about !== ''") 概要：
    //-       //- p.px-3.scroll.fz14.mb-0(v-if="selectedOrder.about !== ''") {{ selectedOrder.about }}
    //-       div.f.fh.mt-3
    //-         v-btn(color="#E7305B" @click="actionBy(selectedOrder)")
    //-           span.btn-span 削除する

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-mypage {
  width: 100%;
  min-height: 100vh;
  padding-top: 48px;
  .user-profile {
    .logout {
      color: #999;
    }
  }
  .f18 {
    font-size: 18px;
    position: relative;
    top: -4px;
  }

  .wrap-order-lists {
  width: 100%;
  max-height: 100vh;
  padding-bottom: 62px;
  .orders {
    margin: 0 auto;
    max-width: $base_max_width_px;
  }
}

  .order-content {
    overflow-y: scroll;

    span {
      position: relative;
      top: 2px
    }

    .scroll {
      overflow-y: scroll;
      max-height: 160px;
    }
  }
}

.btn-span {
    font-weight: bold;
    color: #fff ;
    top: 0px !important;
  }
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import ItemOrderCard from '@/components/item/ItemOrderCard'
import MinNowLoading from '@/components/shared/MinNowLoading'
import { functions } from '@/components/utils/firebase'
import { database } from '../../../functions/shared/database'
import { gmapApi } from 'vue2-google-maps'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } =
  createNamespacedHelpers('auth')

export default {
  components: {
    ItemOrderCard,
    MinNowLoading
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    google: gmapApi,
    displayCategoryText () {
      switch (this.selectedOrder.category) {
        case 'shopping':
          return '買物代行のお仕事'
        case 'other':
          return 'その他のお仕事'
      }
    },
    displayTextTime () {
      return `~${String(this.selectedOrder.time)}分`
    },
    displayCategoryIcon () {
      switch (this.selectedOrder.category) {
        case 'shopping':
          return 'mdi-cart'
        case 'other':
          return 'mdi-briefcase'
      }
    },
    displayUrgency () {
      if (this.selectedOrder.urgency === 'anytime') {
        return 'いつでも'
      } else {
        return 'なるべく早く'
      }
    }
  },
  data () {
    return {
      email: null,
      cards: [],
      sum: 0,
      withdrawNum: 30000,
      showWithdrawInput: false,
      orders: [],
      messageRooms: [],
      address: null,
      geocoder: {},
      inputAddress: '',
      paymentUrl: null,
      isShowDialog: false,
      dialogType: null,
      isShowLoading: false,
      isShowDetail: false,
      selectedOrder: null
    }
  },
  async mounted () {
    await this.$gmapApiPromiseLazy().then(() => {
      this.geocoder = new google.maps.Geocoder()
    })

    const unsubscribeUpdateOrders = database.orderCollection().getMyOrderSnap(this.uid,
      this.updateOrders
    )
    this.setUnsubscribeUpdateOrders(unsubscribeUpdateOrders)
    const unsubscribeUpdateMessageRooms = database.messageRoomCollection().getMyMessageRoomSnap(this.uid,
      this.updateMessageRooms
    )
    this.setUnsubscribeUpdateMessageRooms(unsubscribeUpdateMessageRooms)
    this.selectedOrder = this.orders[0]
    const addressList = await database.addressCollection(this.uid).all()
    this.address = addressList[0]
    this.inputAddress = this.address.address
  },
  methods: {
    ...mapActionsAuth([
      'signOut',
      'setUnsubscribeUpdateOrders',
      'setUnsubscribeUpdateMessageRooms'
    ]),
    updateOrders (orders) {
      if (this.messageRooms.length > 0) {
        for (const order of orders) {
          const index = this.messageRooms.findIndex(room => room.id === order.workId)
          if (index >= 0) {
            order.messageRoom = this.messageRooms[index]
          }
        }
      }
      if (this.orders.length > 0) {
        orders.forEach(e => {
          const index = this.orders.findIndex(order => order.id === e.id)
          if (index >= 0) {
            this.$set(this.orders, index, e)
          } else {
            this.orders.push(e)
          }
        })
      } else {
        this.orders = orders
      }
    },
    updateMessageRooms (rooms) {
      for (const room of rooms) {
        const index = this.orders.findIndex(order => order.workId === room.id)
        if (index >= 0) {
          this.$set(this.orders[index], 'messageRoom', room)
        }
      }
      this.messageRooms = rooms
    },
    async actionBy (order) {
      if (order.status === 'created') {
        if (this.order.paymentType === 'paypay') {
          const expiredAt = order.servicePayment.expiredAt.toDate()
          this.dialogType = 'payment'
          this.isShowDialog = true
          if (expiredAt.getTime() - 60000 >= new Date().getTime()) {
            this.paymentUrl = order.servicePayment.url
            this.isShowLoading = false
          } else {
            this.isShowLoading = true
            const userAgent = navigator.userAgent
            const req = {
              orderId: order.id,
              codeId: order.servicePayment.codeId,
              type: 'servicePayment',
              merchantPaymentId: order.id,
              amount: (order.agencyFee) ? order.serviceFee + order.agencyFee : order.serviceFee + order.bonus,
              orderDescription: (order.bonus > 0 || order.agencyFee) ? 'システム利用料 + 代行費' : 'システム利用料',
              userAgent: userAgent
            }

            const reCreateQRCode = functions.httpsCallable('reCreatePayPayQRCode')
            reCreateQRCode({ req }).then((res) => {
              const status = res.data.status
              if (status === 201) {
                this.isShowLoading = false
                const result = res.data.body.data
                this.paymentUrl = result.url
              } else {
                window.alert('PayPayQRコードの生成に失敗しました。何度もこのエラーが表示される場合はお手数ですが最初からやり直してください。')
              }
            })
          }
        }
      } else if (order.status === 'open') {
        const result = window.confirm('ご依頼をキャンセルしますか？')
        if (result) {
          await database.orderCollection().update(order.id, {
            status: 'removed',
            removedAt: new Date()
          })
          const index = this.orders.findIndex(e => e.id === order.id)
          this.orders.splice(index, 1)
        }
        this.isShowDetail = false
      } else {
        this.$router.push(`/mypage/order/${order.workId}`)
      }
    },
    async deleteOrder (order) {
      const result = window.confirm('ご依頼を削除しますか？')
      if (result) {
        const status = (order.status === 'created' || order.status === 'open') ? 'removed' : 'archived'
        await database.orderCollection().update(order.id, {
          status: status
        })
        const index = this.orders.findIndex(e => e.id === order.id)
        this.orders.splice(index, 1)
      }
    },
    showDetail (order) {
      this.selectedOrder = order
      this.isShowDetail = true
    },
    toPayment () {
      this.isShowDialog = false
      window.location.href = this.paymentUrl
    },
    changeBtnText (order) {
      if (order.status === 'created') {
        return 'お支払い'
      } else if (order.status === 'open') {
        return '削除'
      } else if (order.status === 'done' && order.reviewedAt) {
        return 'メッセージ'
      } else if (order.status === 'done') {
        return 'レビュー'
      } else {
        return 'メッセージ'
      }
    },
    editAddress () {
      this.dialogType = 'editAddress'
      this.isShowDialog = true
    },
    async generateGeocode () {
      if (this.inputAddress === '') {
        window.alert('住所を入力してください。')
        return
      }
      await this.geocoder.geocode({
        address: this.inputAddress
      }, async (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat()
          const lng = results[0].geometry.location.lng()
          const data = {
            address: this.inputAddress,
            lat: lat,
            lng: lng,
            updateAt: new Date()
          }
          await database.addressCollection(this.uid).update(this.address.id, data)
          this.address = { id: this.address.id, ...data }
          this.isShowDialog = false
        } else {
          window.alert('住所が正しく登録できませんでした。もう一度お試しください')
        }
      })
    },
    logout () {
      this.signOut()
      setTimeout(() => {
        this.$router.push('/sign-in')
      }, 400)
    }
  }
}
</script>
