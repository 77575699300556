<template lang="pug">
  div(@click="emitAction").wrap-item-order-card
    div.category.mb-1.f.flex-between
      div.f
        div
          //- v-icon {{ displayCategoryIcon }}
          v-icon mdi-cart
          span.fz-16.bold.ml-2 お買物代行
        div.ml-2.f
          div(:style="`background-color: ${order.status === 'done' ? 'gray' : '#e7305b'}; margin-top: 10px; width: 8px; height: 8px; border-radius: 4px;`")
          p(:style="`margin-top: 5px;`").fz12.mb-0.ml2 {{ displayStatus }}
      v-icon(v-if="order.status === 'created' || order.status === 'open' || order.status === 'done'" @click="emitDelete") mdi-close
    div.place
      v-icon mdi-map
      span.fz12.pl-2 {{ order.address }}
    div.f.flex-between
      div.mr-2
        div.time-agency-fee-container.mb-1
          div.time(v-if="order.urgency")
            v-icon mdi-clock-time-five
            span.pl-2.bold.fz14 {{ displayUrgency }}
          div.agency-fee
            v-icon mdi-cash-multiple
            span.px-2.fz12 代行費:
            span.bold.fz14 {{ (order.agencyFee ? order.agencyFee : order.bonus) }}
            span.fz12 円
      div.button-div.mt-3(v-if="btnText")
        v-btn(@click.stop="emitBtn" color="#E7305B")
          v-icon.fz16(color="white" v-if="order.status !== 'open' || (order.status === 'done' && !order.reviewedAt)") mdi-chat-processing
          span.bold.pl-1.fz14 {{btnText}}
        div.message-badge(v-if="order.messageRoom && order.messageRoom.customerUnReadMessageCount > 0 && (order.status !== 'done' || order.reviewedAt)")
          p.fz12.bold {{ order.messageRoom.customerUnReadMessageCount }}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-item-order-card {
  margin: 0 auto;
  max-width: 448px;
  width: calc(100% - 16px);
  border-radius: 4px;
  cursor: pointer;
  padding:8px;
  border: 1px solid $body_tertiary;

  .category {
    span {
      position: relative;
      top: 2px
    }
  }

  .button-div {
    position: relative;
    display: flex;
    align-items: flex-end;
    span {
      color: #fff;
    }
    .message-badge {
      position: absolute;
      z-index: 1px;
      top: -6px;
      right: -6px;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.6);
      background-color: $body_main;

      p {
        text-align: center;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }

  .time-agency-fee-container {
    span {
      position: relative;
      top: 2px;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    btnText: {
      type: String
    }
  },
  data () {
    return {
      clippedSubText: ''
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    displayCategoryIcon () {
      switch (this.order.category) {
        case 'shopping':
          return 'mdi-cart'
        case 'other':
          return 'mdi-briefcase'
      }
    },
    displayCategoryText () {
      switch (this.order.category) {
        case 'shopping':
          return '買物代行のお仕事'
        case 'other':
          return 'その他のお仕事'
      }
    },
    displayStatus () {
      switch (this.order.status) {
        case 'open':
          return 'マッチング中'
        case 'assigned':
          return 'お届け待ち'
        case 'delivered':
          return 'お支払い待ち'
        case 'done':
          return '完了済み'
      }
    },
    displayTime () {
      return `~${String(this.order.time)}分`
    },
    displayUrgency () {
      if (this.order.urgency === 'anytime') {
        return 'いつでも'
      } else {
        return 'なるべく早く'
      }
    }
  },
  methods: {
    emitBtn () {
      this.$emit('btn-action', this.order)
    },
    emitAction () {
      this.$emit('action', this.order)
    },
    emitDelete () {
      this.$emit('delete', this.order)
    }
  }
}
</script>
