<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="loginFailed")
    NowLoading(:show="showNowLoading")
    ModalController(ref="modalController"
      :rootHeader="header")
      ModuleMyPage(v-if="uid" :user="user")
    Footer
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

</style>

<script>
import { firebase } from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/shared/Auth'
import NowLoading from '@/components/shared/NowLoading'
import Header from '@/components/shared/Header'
import Footer from '@/components/shared/Footer'
import ModalController from '@/components/shared/ModalController'
import ModuleMyPage from '@/components/module/ModuleMyPage'
import ItemOrderCard from '@/components/item/ItemOrderCard'
import { database } from '../../functions/shared/database'
import liff from '@line/liff'
import { liffId } from '@/components//utils/config'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    NowLoading,
    Header,
    Footer,
    ModalController,
    ModuleMyPage,
    ItemOrderCard
  },
  data: () => ({
    header: {
      title: 'マイページ',
      left: {
        icon: 'mdi-hand-wave',
        iconText: '依頼',
        textColor: '#686868',
        to: '/create-order'
      }
    },
    showNowLoading: true,
    user: null
  }),
  computed: {
    ...mapStateAuth(['isLoggedIn', 'uid'])
  },
  methods: {
    async loginFailed () {
      this.$router.push('/sign-in')
    },
    async onLoggedIn () {
      this.user = await database.userCollection().findById(this.uid)
      this.showNowLoading = false
    }
  }
}
</script>
